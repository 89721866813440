import React, { FormEvent, useEffect } from "react";
import { TranslatableStringInterface } from "../interfaces/translatable-string.interface";
import TextInput from "../components/utils/TextInput";
import { applyCode } from "../store/actions/cart.action";
import { useLocation } from "@reach/router";
import { translationHelper } from "../helpers/translation.helper";
import { useTranslation } from "react-i18next";
import { usePrevious } from "../hooks/use-previous.hook";
import CartCodesUsed from "../components/cart/cart-codes-used.component";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import { TranslationKeys } from "../interfaces/translation-keys.enum";
import { useAppDispatch, useAppSelector } from "../hooks/use-redux.hook";
import { Link } from "gatsby";

interface CampaignCodeWidgetProps {
    title?: TranslatableStringInterface;
    description?: TranslatableStringInterface;
    redirect_url?: string;
    showButton?: boolean;
}

const CampaignCodeWidget: React.FC<CampaignCodeWidgetProps> = (props) => {
    const { title, description, redirect_url, showButton } = props;

    const { t, i18n } = useTranslation();
    const location = useLocation();

    const [code, setCode] = React.useState("");

    const [showBanner, setShowBanner] = React.useState(false);

    const dispatch = useAppDispatch();
    const cart = useAppSelector(state => state.cart);

    const prevUsedCodes = usePrevious(cart.codes_used);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        dispatch(applyCode(code, redirect_url));
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const codeParam = params.get("c");
        if (codeParam) {
            setCode(codeParam);
        }
    }, []);

    // Odotetaan, että ostoskori on latautunut, jonka jälkeen lisätään koodi ostoskoriin ja uudelleenohjataan annetulle sivulle
    useEffect(() => {
        if (!cart.created) return;
        const params = new URLSearchParams(location.search);
        const codeParam = params.get("c");
        const redirectParam = params.get("r");
        if (codeParam && redirectParam) {
            const usedCode = cart.codes_used.find(code => code.code === codeParam);
            if (usedCode) {
                console.log("Code already exists in cart. Redirecting...");
                window.location.href = redirectParam;
                return;
            }
            dispatch(applyCode(codeParam, redirectParam));
        }
    }, [cart.created, cart.codes_used.length]);


    useEffect(() => {
        if (!prevUsedCodes) return;
        if (cart.codes_used.length > prevUsedCodes.length) {
            setShowBanner(true);
        }
    }, [cart.codes_used.length]);

    return (
        <div className="card card-content" id="campaign-code-widget">
            {showBanner && (
                <div className="rounded-md bg-green-50 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-green-400" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm leading-5 font-medium text-green-800">
                                {t(TranslationKeys.CART_CODE_APPLIED)}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    className="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150"
                                    aria-label={t(TranslationKeys.COMMON_CLOSE)}
                                    onClick={() => setShowBanner(false)}
                                >
                                    <XIcon className="h5 w-5" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <p className="text-lg leading-6 font-medium text-gray-900">
                {title
                    ? translationHelper(title, i18n.language)
                    : t(TranslationKeys.CART_CODE_FIELD_TITLE)}
            </p>
            <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                <p>
                    {description
                        ? translationHelper(description, i18n.language)
                        : t(TranslationKeys.CART_CODE_FIELD_DESCRIPTION)}
                </p>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="mt-5 sm:flex sm:items-center">
                    <div className="max-w-xs w-full">
                        <TextInput
                            onChange={(event) =>
                                setCode(event.target.value.trim())
                            }
                            value={code}
                        />
                    </div>
                    <span className="mt-3 inline-flex rounded-md shadow-sm sm:mt-1 sm:ml-3 sm:w-auto">
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={code.length === 0}
                            title={code.length === 0 ? t(TranslationKeys.CART_CODE_APPLY_TITLE) : ""}
                        >
                            {t(TranslationKeys.CART_CODE_APPLY)}
                        </button>
                    </span>
                </div>
            </form>
            <CartCodesUsed />
            { showButton && <div className="mt-6">
                <Link
                    className="btn btn-secondary text-center"
                    to="/"
                >
                    {t(TranslationKeys.COMMON_BACK_TO_SHOPPING)}
                </Link>
            </div> }
        </div>
    );
};

export default CampaignCodeWidget;
