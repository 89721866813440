import React, { useEffect } from "react";
import Layout from "../../../layout.component";
import { Link } from "gatsby";
import { priceHelper } from "../../../../helpers/price.helper";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../../../interfaces/translation-keys.enum";
import { LoadingSpinnerIcon } from "../../../icons/loading-spinner.icon";
import CartIsEmpty from "./cart-is-empty.component";
import CampaignCodeWidget from "../../../../widgets/campaign-code.widget";
import { ICartItem } from "../../../../interfaces/cart-item.interface";
import { translationHelper } from "../../../../helpers/translation.helper";
import CartItem from "../checkout/cart-items/cart-item.component";
import { useAppSelector } from "../../../../hooks/use-redux.hook";

const Cart = (): JSX.Element => {

    const [groups, setGroups] = React.useState<Map<string, ICartItem[]>>(new Map());
    const [groupedItems, setGroupedItems] = React.useState<(JSX.Element | Element)[]>([]);

    const { event, cart } = useAppSelector(state => state);
    const { events } = event;
    const { t, i18n } = useTranslation();

    const buildItemCategories = React.useCallback(
        (groupItems: Map<string, ICartItem[]>) => {
            if (!event.isLoaded) return;
            // Laitetaan productGroup ensimmäiseksi listalla
            let items = new Map(
                [...groupItems].sort(([k, v]) => {
                    if (k === "productGroup") {
                        return -1;
                    }
                    return 0;
                }),
            );

            items.forEach((items, event_id) => {
                const event = events.find((event) => event.id === event_id);
                const groupItem = (
                    <li key={event_id} className="card">
                        <div
                            className="card-title border-b border-gray-200">{event ? translationHelper(event.title, i18n.language) : t(TranslationKeys.CART_ITEMS_IN_CART)}</div>
                        <div className="space-y-2">
                            {items.map((item) => (
                                <CartItem item={item} showControls={true} key={item.id} />
                            ))}
                        </div>
                    </li>
                );
                setGroupedItems((old) => [...old, groupItem]);
            });
        },
        [events],
    );

    useEffect(() => {
        if (!event.isLoaded) return;
        const tempGroups = new Map<string, ICartItem[]>();
        setGroupedItems([]);
        cart.items.forEach((item) => {
            if (!item.product) return;
            let event = item.product.event_id || "productGroup";
            let eventItems = tempGroups.get(event);
            if (eventItems) {
                eventItems = eventItems.filter(
                    (eventItem) => eventItem.id !== item.id,
                );
                eventItems.push(item);
                tempGroups.set(event, eventItems);
            } else {
                tempGroups.set(event, [item]);
            }
        });
        setGroups(tempGroups);
    }, [JSON.stringify(cart.items), events]);

    useEffect(() => buildItemCategories(groups), [groups, buildItemCategories]);

    if (cart.items.length === 0) {
        return <CartIsEmpty />;
    }


    return (<Layout>
            <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-0 py-6 sm:py-0">
                <div className="space-y-4">
                    <section aria-labelledby="cart-heading">
                        <ul className="space-y-2">
                            {groupedItems}
                        </ul>
                    </section>
                    <section aria-labelledby="campaign-code">
                        <h2 id="campaign-code" className="sr-only">
                            {t(TranslationKeys.ORDER_SUMMARY)}
                        </h2>
                        <div>
                            <CampaignCodeWidget />
                        </div>
                    </section>
                    <section aria-labelledby="summary-heading" className="card card-content">
                        <h2 id="summary-heading" className="sr-only">
                            {t(TranslationKeys.ORDER_SUMMARY)}
                        </h2>

                        <div className="mb-4">
                            <dl className="space-y-4">
                                <div className="flex items-center justify-between">
                                    <dt className="text-base font-medium text-gray-900">{t(TranslationKeys.ORDER_TOTAL)}</dt>
                                    <dd className="ml-4 text-base font-medium text-gray-900">{priceHelper(cart.totalEur)}</dd>
                                </div>
                            </dl>
                        </div>
                        <div className="mt-10">
                            <Link
                                to="/checkout"
                                className="btn btn-primary w-full btn-icon btn-icon-left text-center"
                            >
                                {cart.isLoading && <LoadingSpinnerIcon className="text-white animate-spin-slow" />}
                                {t(TranslationKeys.ORDER_PROCEED_TO_CHECKOUT)}
                            </Link>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    );
};

export default Cart;
