import React, { FormEvent } from "react";
import { removeCode } from "../../store/actions/cart.action";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../interfaces/translation-keys.enum";
import { useAppDispatch, useAppSelector } from "../../hooks/use-redux.hook";

const CartCodesUsed: React.FC = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const cart = useAppSelector(state => state.cart);

    const handleCodeRemove = (event: FormEvent, code: string) => {
        event.preventDefault();
        dispatch(removeCode(code));
    };

    if (cart.codes_used.length === 0) {
        return <></>;
    }

    return (
        <>
            <div className="mt-4">
                <p className="block text-sm font-medium leading-5 text-gray-700">
                    {t(TranslationKeys.CART_CODES_USED)}
                </p>
                <div className="mt-1 flex space-x-2">
                    {cart.codes_used.map((code) => (
                        <button
                            data-testid="used-code-button"
                            onClick={(event) =>
                                handleCodeRemove(event, code.code)
                            }
                            key={code.id}
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                            {code.code}{" "}
                            <span className="font-bold text-lg ml-1">
                                &times;
                            </span>
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default CartCodesUsed;
